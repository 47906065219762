/* eslint-disable no-empty */
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'

const ContentFiltersContext = createContext('')

const ContentFiltersProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [genreFilter, setGenreFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])
  const [locations, setLocations] = useState([])
  const [galleryOptions, setGelleryOptions] = useState([])

  const getGenre = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'genre',
      params: {
        lang: i18n.language,
      },
    })

    return result
  }

  const getAllTypes = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'type',
      params: {
        lang: i18n.language,
      },
    })

    return result
  }

  const getLocations = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'location',
      params: {
        lang: i18n.language,
      },
    })

    return result
  }

  const getGalleryOptions = async () => {
    const headers = {
      'Content-Type': 'application/json',
    }
    const result = makeRequest({
      headers,
      endPoint: 'gallery_category',
      params: {
        lang: i18n.language,
        _fields: 'id,name,slug,count,description,taxonomy',
      },
    })

    return result
  }

  const getContentFilters = async () => {
    try {
      const [
        genreResult,
        locationsResults,
        allTypesResult,
        allGalleryCategories,
      ] = await Promise.all([getGenre(), getLocations(), getAllTypes(), getGalleryOptions()])
      setGenreFilter(genreResult.data)
      setLocations(locationsResults.data)
      setTypeFilter(allTypesResult.data)
      setGelleryOptions(allGalleryCategories.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getContentFilters()
  }, [])

  const values = {
    genreFilter,
    locations,
    typeFilter,
    galleryOptions,
  }

  return (
    <ContentFiltersContext.Provider value={values}>
      {children}
    </ContentFiltersContext.Provider>
  )
}

const useContentFilters = () => {
  const context = useContext(ContentFiltersContext)

  if (!context) { throw new Error('useLocale must be used within an ContentFiltersProvider') }

  return context
}

export { ContentFiltersProvider, useContentFilters }
