import React from 'react'
import _ from 'lodash'
import ItemMenuMobile from '../item-menu-mobile'

const ListMenuMobile = ({
  menu,
  pathname,
  toggleMenu,
}) => {
  if (_.isEmpty(menu)) return null

  return (
    <React.Fragment>
      {_.map(menu, (item, idx) => (
        <ItemMenuMobile
          item={item}
          key={idx}
          idx={idx}
          pathname={pathname}
          toggleMenu={toggleMenu}
        />
      ))}
    </React.Fragment>
  )
}

export default ListMenuMobile
