import React, {
  createContext,
  useState,
  useContext,
} from 'react'

const UIContext = createContext('')

const UIProvider = ({ children }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const values = {

    isOpenModal,
    handleModal,
  }

  return (
    <UIContext.Provider value={values}>
      {children}
    </UIContext.Provider>
  )
}

const useUI = () => {
  const context = useContext(UIContext)

  if (!context) { throw new Error('useLocale must be used within an UIProvider') }

  return context
}

export { UIProvider, useUI }
