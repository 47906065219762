import _ from 'lodash'
import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import './button-style.scss'
import ArrowRight from '../../assets/seasongroup_icons_arrow-right.svg'
import ExternalLinkIcon from '../../assets/icons/seasongroup_icons_open-external-link.svg'

const Button = ({
  text,
  link,
  onClick,
  light,
  external,
  disabled,
  type = 'text',
}) => {
  const btnClass = classNames({
    button__container: true,
    light,
    disabled,
  })

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }

  switch (true) {
    case external:
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button
            type={type}
            onClick={handleOnClick}
            className={btnClass}
          >
            <span>{text}{external ? <ExternalLinkIcon width="17" /> : <ArrowRight width="17" />}</span>
          </button>
        </a>
      )
    case !_.isEmpty(link):
      return (
        <Link to={link}>
          <button
            type={type}
            onClick={handleOnClick}
            className={btnClass}
          >
            <span>{text}{external ? <ExternalLinkIcon width="17" /> : <ArrowRight width="17" />}</span>
          </button>
        </Link>
      )

    default:
      return (
        <button
          type={type}
          onClick={handleOnClick}
          className={btnClass}
        >
          <span>{text}<ArrowRight width="17" /></span>
        </button>
      )
  }
}

export default React.memo(Button)
