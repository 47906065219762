/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const shouldUpdateScroll = ({
  routerProps: { location },
}) => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }

  return false
}

export { wrapRootElement } from './gatsby-ssr'
