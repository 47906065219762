import React, { useEffect } from 'react'
import { withTrans } from '../../i18n/withTrans'
import '../../theme/_global.scss'
import Header from '../header'
import Footer from '../footer'
import './layout-styles.scss'
import _ from 'lodash'

let initialLoad = false;

const Layout = ({
  children,
  t,
  i18n,
  location,
}) => {
  console.log('🚀 ~ file: index.js:15 ~ location', location)
  const switchLanguage = () => {
    console.log("Layout 2");
    let language
    switch (true) {
    //   case _.includes(location.pathname, 'zh-CN'):
    //     language = 'jp'
    //     break
    //   case _.includes(location.pathname, 'zh-TW'):
    //     language = 'zhTW'
    //     break
    case _.includes(location.pathname, 'zh-hant'):
        language = 'zh-hant'
        break;
      default:
        language = 'en'
        break
    }
    i18n.changeLanguage(language)
  }

  if(!initialLoad){
    initialLoad = true;
    switchLanguage();
  }

  useEffect(() => {
    switchLanguage();
  }, [location])
  return (
    <div className="global-wrapper layout__container">
      <Header pathname={location.pathname} />
      {children}
      <Footer t={t} i18n={i18n}/>

    </div>

  )
}

export default withTrans(Layout)
