/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { withTrans } from '../../i18n/withTrans'
import './header-styles.scss'
import { useSettings } from '../../hooks/use-settings'
import Button from '../button'
import LanguageSelector from '../language-selector'
import Icon from '../icon/index.jsx'
import {
  HamburgerMenu,
  ListMenuDesktop,
  MobileBottomMenu,
  ListMenuMobile,
} from './components'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'

const Header = ({ t, pathname }) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [open, setOpen] = useState(null)
  const { settings, menu } = useSettings()
  const { i18n } = useTranslation();
  const location = useLocation();

  const button = settings?.button?.text
  const breakpoints = useBreakpoint()

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu)
  }
  const toggleSubMenu = (link) => {
    setOpen(link)
  }

  // console.log('menu set ', settings)
//   console.log('HEADLANG', i18n.language);
//   console.log('HEADURL', `${i18n.language === 'en' ? '/' : `/${i18n.language}/`}`);

  return (
    <div className="header__container">
      <Helmet>
        <body className={mobileMenu ? 'is-open-menu' : ''} />
      </Helmet>
      <div className="header__sub-container">
        <div className="header__logo">
          <Link to={`${!location.pathname.includes('zh-hant') ? '/' : `/zh-hant/`}`}>
            <Icon name="logo" />
          </Link>
        </div>
        <div className={mobileMenu ? 'header__toggleClose' : 'header__menu'}>
          <ul className="header__menu__list">
            {breakpoints.sm || breakpoints.md ? (
              <ListMenuMobile
                pathname={pathname}
                menu={menu}
                toggleMenu={toggleMenu}
              />
            ) : (
              <ListMenuDesktop
                menu={menu}
                breakpoints={breakpoints}
                toggleSubMenu={toggleSubMenu}
                open={open}
                pathname={pathname}
              />
            )}

            <li className="header__mobile">
              <MobileBottomMenu />
            </li>
          </ul>
        </div>
        <div className="header__sub-menu">
          <ul>
            <li>
              <div className="header__select header__desktops">
                <LanguageSelector />
              </div>
            </li>
            <li className="header__search">
              <Link to={`${!location.pathname.includes('zh-hant') ? '/search/' : `/zh-hant/search/`}`}>
                <Icon name="search" />
              </Link>
            </li>
            <Button link={`${!location.pathname.includes('zh-hant') ? '/work-with-us/' : `/zh-hant/work-with-us/`}`} text={button || t('top-menu.work-with-us')} />
            <li className="header__hamburger">
              <HamburgerMenu toggle={toggleMenu} isOpen={mobileMenu} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default withTrans(Header)
