/* eslint-disable prefer-destructuring */
import _ from 'lodash'
import axios from 'axios'


function makeRequest({
  headers,
  method,
  endPoint,
  // body,
  // custom,
  signal,
  params,
  data,
  urlString,
} = {}) {
  const basicHeaders = {
    'Content-Type': 'application/json',
  }
  const urlBase = process.env.GATSBY_API_URL
  console.log('>>>>>>>>>>>>', process.env)
  const url = !_.isEmpty(urlString) ? urlString : `${urlBase}${endPoint}`
  return axios({
    method,
    url,
    data,
    params,
    headers: headers || basicHeaders,
    signal,
  })
    // responding with the data as "data"
    .then((response) => ({
      status: response.status,
      data: response.data,
      total_items: response.headers['x-wp-total'],
      total_pages: response.headers['x-wp-totalpages'],
    }))
    .catch((error) => error.response.data)
}

export default makeRequest
