import React from "react"
import _ from "lodash"
import { Link } from "gatsby"
import classnames from "classnames"
import { useTranslation } from "react-i18next"

const SubListItem = ({ item, subNav, toggleSubMenu }) => {
  const { t, i18n } = useTranslation()

  if (item.link.url === "about-us") {
    return (
      <li onMouseOver={toggleSubMenu}>
        {/* {console.log('about us ', subNav.link.text)} */}
        <Link to={`/${subNav.link.url}/`}>
          <p>
            {subNav.link.text ||
              t(`nav.${item.link.url}.sub_nav.${subNav.link.url}`)}
          </p>
        </Link>
      </li>
    )
  }

  return (
    <li onMouseOver={toggleSubMenu}>
      <Link to={`/${item.link.url}/${subNav.link.url}/`}>
        <p>
          {subNav.link.text ||
            t(`nav.${item.link.url}.sub_nav.${subNav.link.url}`)}
        </p>
      </Link>
    </li>
  )
}

const getActive = (pathname, url) => {
  const pathnamesAbout = [
    "/leadership/",
    "/csr/",
    "/zh-hant/leadership/",
    "/zh-hant/csr/",
  ]
  console.log(pathname, url)
  switch (true) {
    case url && _.includes(pathname, url) && url !== "zh-hant":
      return true
      break
    case (url === "about-us" || url === "zh-hant") &&
      _.includes(pathnamesAbout, pathname):
      return true
      break
    case url === "zh-hant" && pathname.includes("about-us/"):
      return true
      break
    default:
      return false
  }
}

const ItemListmenu = ({ item, breakpoints, toggleSubMenu, open, pathname }) => {
  const link =
    item.link.url === "services" || item.link.url === "industries"
      ? null
      : `/${item.link.url}/`
  const classList = classnames({
    header__list: true,
    header__list__open: open !== item.link.url,
    isactive: getActive(pathname, item.link.url),
  })

  const { t, i18n } = useTranslation()
  //   console.log(i18n.language, t(`nav.${item.link.url}.label`));

  return (
    <li
      className={classList}
      onMouseEnter={!breakpoints.xs ? () => toggleSubMenu(item.link.url) : null}
    >
      {link ? (
        <Link to={link} className="list-link">
          {item.link.text || t(`nav.${item.link.url}.label`)}
        </Link>
      ) : (
        <p className="list-text">
          {item.link.text || t(`nav.${item.link.url}.label`)}
        </p>
      )}
      {/* {console.log('text ', item.link.text)} */}
      {item.sub_nav && (
        <div
          onMouseOver={() => toggleSubMenu(item.link.url)}
          onMouseOut={() => toggleSubMenu(null)}
          className="block__submenu"
        >
          <ul
            className={
              open === item.link.url
                ? "header__submenu header__submenu--open"
                : "header__submenu"
            }
          >
            {_.map(item.sub_nav, (subNav, index) => (
              <React.Fragment key={subNav.link.text + index}>
                <SubListItem
                  subNav={subNav}
                  item={item}
                  toggleMenu={toggleSubMenu}
                />
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </li>
  )
}

export default ItemListmenu
